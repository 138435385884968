.quotelo-sidebar {
    width: 260px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    font-family: 'Lato', sans-serif !important;
}

.sidebar-logo {
    display: block;
    margin-bottom: 120px !important;
    padding-top: 40px !important;
    max-height: 100px !important;
    margin-left: 39px !important;
    object-fit: scale-down;
}

.menu-item {
    color: #d9d9d9 !important;
    text-align: left !important;
    margin-left: 43px !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.ui.menu .active.item {
    color: #0032a0 !important;
    background: none !important;
    font-weight: bold !important;
    border-right: 5px solid #0032a0 !important;
}

.content {
    height: 100vh;
    width: calc(100% - 260px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ui.basic.segment {
    padding: 0px !important;
}

.menu-item-icon {
    width: 24px !important;
    height: 24px;
    margin-right: 13px !important;
}

.menu-separator {
    display: flex !important;
    flex-direction: column !important;
    height: calc(100% - 280px);
    justify-content: space-between;
}

.logOutTitle {
    position: relative;
    font-weight: 900;
    font-size: 25px;
    line-height: 130%;
    font-family: 'Red Hat Display', sans-serif;
    color: #292724;
    width: 360px;
    height: 64px;
    margin: 40px;
    text-align: center;
}

.logOutButton {
    position: relative;
    width: 360px;
    height: 48px;
    text-transform: none !important;
    margin: 10px 40px !important;
    background: #0032a0 !important;
    border-radius: 4px !important;
    color: white !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 800 !important;
    font-size: 14px !important;
}

.cancelButton {
    position: relative;
    width: 360px;
    height: 48px;
    text-transform: none !important;
    margin: 10px 40px !important;
    background: white !important;
    border-radius: 4px !important;
    color: #666666 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    border: 1px solid #d9d9d9 !important;
}

.cancelIcon {
    position: relative;
    width: 26px;
    height: 26px;
    float: right;
    margin-top: 15px;
    margin-right: 15px;
    color: #0032a0;
    cursor: pointer;
}

.pushable:not(body) {
    transform: none !important;
}

.pushable > .pusher {
    overflow: unset !important;
}

.ui.segment {
    border: none !important;
}

.ui.segment.pushable {
    position: static;
    width: -webkit-fill-available;
}

.pushable:not(body) > .ui.sidebar {
    position: fixed;
}
