.study-day-card-description {
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
    overflow-wrap: break-word;
    line-height: 150%;
    min-height: 45px;
    margin-top: 5px;
}

.study-day-options-card {
    text-align: center;
    margin-bottom: 3px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 153px;
    background: #eec6f6;
    border-radius: 4px;
    padding: 4px 8px;
}

.study-day-options-card-config {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 140px;
    height: 26px;
    background: #eec6f6;
    border-radius: 4px;
    padding: 4px 8px;
}

.study-day-selection {
    text-transform: none !important;
    width: 134px;
    height: 48px;
    justify-content: center;
    padding: 13.5px 27px;
}

.study-day-selection-button {
    margin-top: 32px;
}

.wrapper {
    display: grid;
    float: left;
    margin-top: 10px;
    grid-template-rows: 140px 140px 140px;
}

.wrapper-container {
    width: 500px;
    display: grid;
    float: left;
    margin-top: 10px;
    grid-template-columns: 165px 165px 165px;
}

.wrapper-button {
    display: grid;
    grid-template-rows: 20px;
}

.study-day-popup-area {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #43433d;
    width: 170px;
    height: 42px;
    text-align: left;
    margin-bottom: 10px;
}
.study-day-popup-area-title {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    color: #666666;
    text-align: left !important;
    width: 170px;
    height: 42px;
}

.study-day-popup-viewMoreDetailsButtonTitle {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #43433d;
    width: 360px;
    height: 21px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
}

.config-modal-details-quantity {
    color: #666666;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    width: 179px;
    height: 21px;
    margin-left: 130px;
    margin-top: 8px;
    text-align: right;
    float: right;
}

.study-day-popup-title {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #43433d;
    width: 180px;
    height: 42;
    text-align: left;
    margin-bottom: 10px;
}

.study-day-popup-subtitle {
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
    overflow-wrap: break-word;
    line-height: 150%;
    height: 20px;
    min-height: 45px;
}

.success-modal-return-button-study-day {
    margin-top: 20px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
    margin-bottom: 20px;
}

.study-day-details-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    margin-left: 10px;
    
}

.study-day-icon-container {
    margin-top: 6px;
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
}
.study-day-container {
    display: flex;
    height: 28px;
    margin-top: 8px;
}

.study-day-icon-subtitle {
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
    font-weight: 700;
}

.study-day-popup-description-title {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #43433d;
    width: 360px;
    height: 21px;
    text-align: left;
}

.Modifier-return-button-study-day {
    width: 139px;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
    margin-bottom: 20px;
}

.Annuler-modal-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
}

.study-day-accommodation-card-quantity-div {
    position: relative;
    font-family: Lato !important;
    font-size: 12px !important;
    font-weight: 800 !important;
    color: #ffffff !important;
    padding: 4px 8px !important;
    
    border-radius: 4px !important;
    margin-right: 8px !important;
    margin-bottom: 8px;
}

.study-day-equipments {
    display: grid;
    grid-template-columns: auto auto auto;
}

.study-day-details-icon-label {
    margin-top: 6px;
    position: relative;
    width: 211px;
    height: 21px;
    text-align: left;
    float: left;
    font-family: Lato !important;
    font-size: 14px !important;
    color: #666666;
}

.client-study-day-select-button {
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    color: #ffffff;
    font-size: 14px;
}

.study-day-accommodation-cards-description-container {
    position: relative;
    width: 483px;
    margin-left: 25px;
}

.study-day-accommodation-card-details-container {
    display: flex;
    justify-content: space-between;
    padding: 24px 0px;
}

.div-disabled {
    pointer-events: none;
    opacity: 0.5;
    background: white;
}
