.logo {
    width: 166px;
    height: 84px;
    margin-top: 14px;
}

.banner {
    position: absolute;
    left: 40%;
    top: 0px;
    width: 60%;
    height: 100%;
    object-fit: cover;
    z-index: 100;
}

.container {
    width: 40%;
    margin-left: 80px !important;
    margin-right: 10px !important;
}

.login-container {
    max-width: 330px !important;
}

.login-title {
    position: relative;
    width: 330px;
    line-height: 130%;
    margin-top: 70px;
    font-style: normal;
    font-weight: 900;
    font-size: 31px;
    display: flex;
    text-align: start;
    letter-spacing: 0.02em;
    font-family: 'Red Hat Display', sans-serif !important;
    color: #292724 !important;
}

.login-subtitle {
    position: relative;
    font-family: 'Lato', sans-serif !important;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    text-align: start;
    letter-spacing: 0.02em;
    color: #666666;
    margin-top: 20px;
    margin-bottom: 40px;
}

.email-field {
    display: flex !important;
    margin-top: 40px !important;
}

.password-field {
    display: flex !important;
    margin-top: 16px !important;
}

.forgot-password {
    margin-top: 12px !important;
    float: right;
    text-transform: none !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #87189d !important;
    font-family: 'Lato', sans-serif !important;
}

.login-button {
    width: 100%;
    height: 48px;
    margin-top: 40px !important;
    text-transform: none !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    font-family: 'Lato', sans-serif !important;
}

.MuiButton-startIcon {
    height: 16px;
    width: 16px;
}
.MuiButton-label {
    padding-top: 1px;
}

.loading {
    align-items: center;
    text-align: center;
    margin-top: 60px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    .container {
        margin-left: 50px !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    .container {
        margin-left: 45px !important;
    }
    .banner {
        left: 50%;
    }
}
