.hotel-config-container {
    height: inherit;
    width: inherit;
}

.empty-state {
    position: relative;
    top: 30%;
}

.empty-state .config-title {
    font-weight: 900;
    font-size: 39px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #d9d9d9;
}

.empty-state .config-details {
    margin-top: 24px;
    font-size: 16px;
    color: #666666;
}

.empty-state .config-button {
    margin-top: 24px;
    width: 122px;
    height: 48px;
    background: #0032a0;
    border-radius: 4px;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    text-transform: none !important;
}

$top: 50%;
$left: 50%;
.hotel-config-modal-container {
    position: relative;
    background-color: #ffffff;
    width: 440px;
    top: $top;
    left: $left;
    transform: translate(-$top, -$left);
    text-align: center;
    padding: 40px;
    outline: none !important;
}

.hotel-config-modal-title {
    font-weight: 900;
    font-size: 25px;
    line-height: 130%;
    font-family: 'Red Hat Display' !important;
}

.hotel-config-modal-description {
    margin-top: 24px;
    font-family: Lato;
    line-height: 150%;
    font-size: 16px;
    color: #666666;
}

.hotel-config-modal-note {
    margin-top: 40px;
    padding: 16px;
    background: #fdf3e4;
    border-radius: 4px;
    font-family: Lato;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    color: #7c3c01;
}

.hotel-config-modal-add-photo {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    color: #43433d;
    margin-top: 32px;
}

.hotel-config-modal-add-photo-button {
    display: flex !important;
    margin-top: 12px !important;
    height: 48px;
    color: #666666 !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
}

.hotel-config-modal-add-photo-button > .MuiButton-label > .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 5px !important;
    height: 24px !important;
    width: 24px !important;
}

.hotel-config-modal-add-photo-button-field {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 48px;
    margin-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    overflow: hidden;
}

.photo-field-container {
    text-align: left;
}

.photo-field-label {
    font-family: Lato;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #0032a0;
}

.photo-field-value {
    font-family: Lato;
    font-size: 14px;
    line-height: 150%;
    color: #666666;
}

.image-upload-icon {
    height: 24px;
    width: 24px;
    align-self: center;
}

.hotel-config-modal-add-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    box-shadow: none !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
}

.hotel-config-modal-add-button.MuiButton-contained.Mui-disabled {
    background: #fafafa !important;
    color: #d9d9d9 !important;
}

.hotel-config-modal-add-button.MuiButton-containedPrimary {
    background: #b9cfff !important;
    color: #001135 !important;
}

.hotel-config-modal-cancel-button {
    margin-top: 20px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.upload-field-error {
    position: relative;
    margin-top: 10px;
    font-family: Lato !important;
    font-size: 14px;
    color: red;
    margin-bottom: 15px;
}

img[src=''],
img:not([src]) {
    visibility: hidden;
}

.hotel-screen-modify-button {
    width: 109px;
    height: 48px;
    margin-right: 80px !important;
    margin-top: 40px !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    background: #b9cfff !important;
    color: #001135 !important;
}

.hotel-config-subtitle {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    margin-top: 16px !important;
    margin-left: 40px;
}

.hotel-config-card-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    margin-top: 50px;
    margin-right: 20px;
}

.hotel-config-card {
    position: relative;
    background: white;
    width: 290px;
    height: 164px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px !important;
    margin-right: 20px !important;
    cursor: pointer;
}

.hotel-config-card-icon-container {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #b9cfff;
}

.hotel-config-card-icon {
    width: 24px;
    height: 24px;
    margin-top: 10px;
}

.hotel-config-card-title {
    position: relative;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #001135;
    text-align: left;
    margin-top: 20px;
}

.hotel-config-card-subtitle {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    margin-top: 8px !important;
}

.hotel-screen-icon {
    width: 24px;
    height: 24px;
}

.hotel-screen-icon-label {
    margin: 3px 62px 0px 11px;
    font-family: Lato !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
}

.hotel-tab-title {
    font-family: Lato !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 150%;
    text-transform: none !important;
}

.hotel-accommodation-card-container {
    margin-left: 40px !important;
    margin-top: 25px !important;
    padding-right: 80px !important;
}

.hotel-accommodation-card {
    position: relative;
    box-shadow: none !important;
    height: auto;
    padding-bottom: 20px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    background: #ffffff !important;
    border-radius: 8px !important;
    margin-right: 25px !important;
    margin-bottom: 32px !important;
}

.hotel-accommodation-card-image-container {
    position: relative;
    height: 155px;
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
}

.hotel-accommodation-card-title {
    position: relative;
    font-family: 'Red Hat Display' !important;
    font-weight: bold !important;
    font-size: 18px !important;
    text-align: left !important;
    color: #43433d !important;
    margin-top: 20px;
    margin-left: 20px;
}

.hotel-accommodation-card-description {
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
    height: auto;
    min-height: 63px;
    overflow: hidden;
    margin: 8px 20px 16px 20px;
}

.hotel-accommodation-event-day {
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
    height: auto;
    min-height: 23px;
    overflow: hidden;
    margin: 8px 20px 16px 20px;
}

.hotel-accommodation-card-quantity-div {
    position: relative;
    height: 26px;
    font-family: Lato !important;
    font-size: 12px !important;
    font-weight: 800 !important;

    padding: 4px 8px !important;
    
    border-radius: 4px !important;
    margin-right: 8px !important;
}

.loading-label {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    font-weight: 800 !important;
    color: #666666 !important;
}
