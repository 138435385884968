.App {
    text-align: center;
}

.backgroundImage {
    background-image: url('./assets/BackgroundLogo.png');
    background-repeat: no-repeat;
    background-position: 100% 40%;
    min-height: 100vh;
}

.role-container {
    display: flex !important;
    justify-content: flex-end;
    padding-top: 20px;
    margin-right: 40px;
}

.role-title {
    position: relative;
    margin-right: 12px;
    font-family: 'Lato', sans-serif;
    color: #5a1069;
    line-height: 150%;
}

.role-bubble {
    position: relative;
    width: 48px;
    height: 48px;
    background: #eec6f6;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: #5a1069;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold !important;
    padding-top: 14px;
}

.breadCrumb-container {
    position: relative;
    display: flex;
    float: left;
    margin-top: -38px;
    margin-left: 45px;
}

.breadCrumb-icon {
    position: relative;
    width: 24px !important;
    height: 24px !important;
    color: #0032a0;
    margin-right: 5px;
    cursor: pointer;
}

.breadCrumb-content {
    position: relative;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    color: #666666;
    line-height: 150%;
    display: flex;
    cursor: pointer;
    margin-right: 5px;
}

.breadCrumb-active-content {
    position: relative;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    color: #0032a0;
    line-height: 150%;
}

.error-text {
    position: relative;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    color: #f44336;
    line-height: 150%;
    margin-bottom: 5px;
    text-align: left;
}
