$top: 50%;
$left: 50%;
.success-modal-container {
    position: relative;
    outline: none !important;
    background-color: #ffffff;
    width: 440px;
    top: $top;
    left: $left;
    transform: translate(-$top, -$left);
    text-align: center;
    padding: 40px;
}

.success-modal-icon {
    position: relative;
    width: 50px;
    height: 50px;
}

.success-modal-title {
    position: relative;
    font-weight: 900;
    font-size: 25px;
    line-height: 130%;
    color: #292724;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0.02em;
    margin-top: 30px;
}

.success-modal-subtitle {
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Lato', sans-serif;
    color: #666666;
    margin-top: 24px;
    letter-spacing: 0.02em;
}

.success-modal-subtitle-client-delete {
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Lato', sans-serif;
    color: #666666;
    margin-top: 24px;
}

.success-modal-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    background: #b9cfff !important;
    color: #001135 !important;
}

.success-modal-return-button {
    margin-top: 20px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.delete-modal-close-icon {
    color: #0032a0;
    width: 26px;
    height: 26px;
    margin-top: -15px;
    margin-right: -15px;
    float: right;
    cursor: pointer;
}

.delete-modal-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    background: #fae8e8 !important;
    color: #620525 !important;
}

.loading-delete-modal-icon {
    margin-top: 15px !important;
}

.parameters-screen-modify-photo {
    margin-top: 24px;
    padding: 16px;
    background: #fdf3e4;
    border-radius: 4px;
    font-family: Lato;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    color: #7c3c01;
}
