.preview-charterGraphical-logo-contained {
  position: absolute;
  left: 40px;
  top: 196px;
  display: flex;
  align-items: center;

  .preview-charterGraphical-logo {
    width: 120px;
    height: 120px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    margin-right: 24px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .preview-charterGraphical-logo-img {
    /*   margin-top: 35%;
      width: 80%;
      height: 29%; */
      object-fit: contain;
      width: 100px;
      height: 100px;
    }
  }

  & .MuiButton-root {
    background-color: #ffffff;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    width: 154px;
    height: 48px;

    & .MuiButton-label {
      text-transform: none;
      font-family: Lato, sans-serif;
      font-weight: 800;
      font-size: 14px;
    }

    &:hover {
      background-color: #ffffff;
      box-shadow: none;
    }
  }
}

.preview-charterGraphical-button {
  position: absolute !important;
  top: 104px;
  right: 80px;
  width: 109px;
  height: 48px;
  box-shadow: none !important;
  background-color: #b9cfff !important;

  & .MuiButton-label {
    font-family: Lato, sans-serif;
    text-transform: none;
    font-weight: 800;
    font-size: 14px;
    color: #001135;
  }
}

.preview-charterGraphical-color {
  position: absolute;
  top: 381px;
  left: 40px;

  .preview-charterGraphical-color-title {
    text-align: left;
    font-family: "Red Hat Display", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #43433d;
    margin-bottom: 12px;
  }

  .preview-charterGraphical-color-input {
    width: 338px;
    height: 48px;

    & .MuiFilledInput-underline:before {
      content: none;
    }

    & .MuiFormLabel-root {
      color: #0032a0 !important;
      font-weight: bold !important;
    }

    & .Mui-disabled {
      background-color: #ffffff;
      color: #666666;
      font-family: Lato, sans-serif;
      font-weight: 400;
      font-size: 14px;
    }

    & .MuiInputBase-root {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}

.preview-charterGraphical-photo {
  position: absolute;
  top: 493px;
  left: 40px;

  .preview-charterGraphical-photo-title {
    text-align: left;
    font-family: "Red Hat Display", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #43433d;
    margin-bottom: 12px;
  }

  .preview-charterGraphical-photo-contained-card {
    display: flex;
    flex-wrap: wrap;
    //width: 692px;
    gap: 16px 16px;

    .preview-charterGraphical-photo-card {
      width: 33% !important;
      height: 133px;
      border-radius: 4px;
      overflow: hidden;
      padding: 5px !important;

      
    }


  }
}

