.client-screen-main-trailing-title {
    position: relative;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #0032a0;
    margin-top: 0px;
    margin-left: 15px;
    line-height: 150%;
    cursor: pointer;
}

.client-Other-Demands-form {
    margin: auto;
    height: 214px;
    display: table;
    width: 740px;
    padding: 20px;
}

.client-Other-Demands-title {
    font-family: Red Hat Display !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    color: #292724;
    display: table-row;
    margin-bottom: 7px;
    float: left;
}

.client-Other-Demands-from-sub-title {
    margin-top: 2px;
    font-family: Lato !important;
    font-size: 16px !important;
    line-height: 150% !important;
    display: table-row;
    color: #666666;
    float: left;
    text-align: justify;
}

.Other-Demands-Plus-Icon {
    margin-right: 15px;
}

.client-Other-Demands-button {
    margin-top: 20px !important;
    width: 225px;
    height: 48px;
    margin-left: 1000px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.client-Other-Demands-input-field {
    position: relative;
    width: 338px;
    margin-top: 30px;
    float: left;
    border-radius: 4px;
    display: table;
    background-color: white !important;
    margin-right: 25px !important;
}
.client-Other-Demands-input-field-second {
    position: relative;
    width: 338px;
    height: 128px;
    margin-top: 10px;
    float: left;
    border-radius: 4px;
    display: table;
    resize: none;
    background-color: white !important;
    margin-right: 10px !important;
}

.Other-Demands-field-align {
    float: left;
}

.Other-Demands-text-form-field {
    margin-top: 10px;
    
    position: relative;
    left: 0;
    float: left;
    display: block;
}


.Other-Demands-grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.wrapper {
    display: grid;
    margin-top: 0px;
    margin-bottom: 0px;
}

.Other-Demands-grid-item {
    padding: 10px;
    font-size: 14px;
    width: 300px;
    text-align: center;
}

.contact-button-container {
    margin-top: 48px;
    margin-left: -20%;
    
}

.contact-button-container-addField-false {
    margin-top: 100px;
    
    display: flex;
    justify-content: center;
}

.contact-precedent-button {
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 180% !important;
    width: 119px;
    height: 48px;
    padding: 27px 13.5px;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.contact-send-request-button.MuiButton-contained.Mui-disabled {
    background: #fafafa !important;
    color: #d9d9d9 !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    margin-right: 270px;
    width: 102px;
    padding: 27px 13.5px;
    height: 48px;
    text-transform: none;
    line-height: 180% !important;
    font-size: 14px !important;
}

.contact-send-request-button.MuiButton-containedPrimary {
    background: #0032a0 !important;
    color: #ffffff !important;
    font-family: Lato !important;
    margin-right: 270px;
    text-transform: none;
    width: 102px;
    height: 48px;
    font-weight: 800 !important;
    line-height: 180% !important;
    font-size: 14px !important;
    margin-left: 20px;
}
