.client-accommodation-accordion {
    position: relative !important;
    background: white;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 0px 24px 0px 24px !important;
    margin: auto;
    width: 745px;
    text-align: left !important;
    box-shadow: none !important;
}

.client-accommodation-accordion-text {
    margin-top: 10px;
    font-family: Red Hat Display !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
}

.client-accommodation-accordion-subtitle {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    margin-top: -20px;
}

.client-accommodation-accordion-quantity-container {
    background: #D9D9D980;
    ;
    border-radius: 4px;
    height: 53px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #01465e;
    padding: 16px;
    margin-top: 24px;
}

.clients-accommodation-card-details-container {
    display: flex;
    justify-content: space-between;
    padding: 24px 0px;
}

.clients-accommodation-card-details-container-blur {
    display: flex;
    justify-content: space-between;
    padding: 24px 0px;
    filter: blur(1px);
    -webkit-filter: blur(1px);
}

.clients-accommodation-cards-image {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 4px;
}

.clients-accommodation-cards-description-container {
    position: relative;
    width: 440px;
}

.client-accommodation-view-more-button {
    padding: 0px !important;
    font-weight: 800 !important;
    font-family: Lato !important;
    font-size: 14px !important;
    text-transform: none !important;
    background: transparent !important;
}

.client-study-day-more-details {
    margin-top: 5px;
    margin-right: 32px;
}
.client-accommodation-card-title {
    position: relative;
    font-family: Lato !important;
    font-size: 24px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    margin-top: 24px;

    width: 100%;
}

.client-accommodation-card-description {
    position: relative;
    font-family: Lato !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    
    margin-bottom: 24px;
    width: 100%;
}
.client-accommodation-card-description-study{
    position: relative;
    font-family: Lato !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    left: 10px;
    margin-bottom: 24px;
    width: 100%;
}

.client-seminar-study-day-card-popup-description {
    position: relative;
    font-family: Lato !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 24px;
    
    width: 335px;
}

.client-accommodation-card-count-value {
    margin: 0px 4px;
    font-weight: bold;
}
