.description {
  position: absolute;
  top: 164px;
  left: 40px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.requiredField {
  position: absolute;
  top: 236px;
  left: 40px;
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #87189d;
}

.block-charterGraphical {
  position: absolute;
  top: 273px;
  left: 40px;
  width: 740px;
  height: 352px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;

  .block-charterGraphical-title {
    position: absolute;
    top: 24px;
    left: 24px;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
  }

  .block-charterGraphical-logo-container {
    position: absolute;
    left: 24px;
    top: 133px;

    .block-charterGraphical-logo-label {
      position: absolute;
      top: -51.5px;
      font-family: "Red Hat Display", sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #43433d;
    }

    .block-charterGraphical-logo-label-info {
      position: absolute;
      top: -25.5px;
      width: 231px;
      font-family: Lato, sans-serif;
      font-size: 12px;
      line-height: 18px;
    }

    .block-charterGraphical-button {
      height: 48px;
      width: 204px;
      border-radius: 4px;
      border: 1px solid #d9d9d9 !important;
      font-family: Lato, sans-serif !important;
      font-weight: 800 !important;
      font-size: 14px !important;
      color: #666666 !important;
      text-transform: none !important;
      background-color: #ffffff;

      .block-charterGraphical-button-icon {
        margin-right: 14px;
      }
    }

    & .MuiFilledInput-root {
      color: #666666;
      font-weight: 400;
      font-family: Lato, sans-serif;
      font-size: 14px;
    }

    & .MuiButton-contained {
      box-shadow: none;
    }

    .block-charterGraphical-input-file-logo {
      height: 48px;
      width: 338px;

      & .MuiFilledInput-root {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: #ffffff;
      }

      & .MuiFormLabel-root.Mui-disabled {
        color: #0032a0;
        font-family: Lato, sans-serif;
        font-weight: 600;
      }

      & .MuiInput-underline:after, :before {
        border-bottom: none;
      }

      &:hover {
        background-color: transparent;
      }

      & .Mui-focused {
        background-color: transparent;
      }
    }

    .block-charterGraphical-iconButton {
      position: absolute;
      right: 3px;
      top: 3px;
      padding: 0;
    }
  }

  .block-charterGraphical-color-container {
    position: absolute;
    top: 221px;
    left: 24px;

    .block-charterGraphical-color-label {
      position: absolute;
      top: 0;
      font-family: "Red Hat Display", sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #43433d;
    }

    .block-charterGraphical-color-label-info {
      position: absolute;
      top: 25.5px;
      width: 291px;
      font-family: Lato, sans-serif;
      font-size: 12px;
      line-height: 18px;
    }

    .block-charterGraphical-color-input {
      position: absolute;
      top: 52px;
      width: 338px;
      height: 48px;

      & .MuiFilledInput-root {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: #ffffff;
        color: #666666;
        font-weight: 400;
        font-family: Lato, sans-serif;
        font-size: 14px;
      }

      & .MuiFormLabel-root {
        font-family: Lato, sans-serif;
        font-weight: 400;
        color: #d9d9d9;
      }

      & .MuiFormLabel-filled {
        color: #0032a0;
        font-weight: 600;
      }

      & .MuiInput-underline:after, :before {
        border-bottom: none;
      }

      &:hover {
        background-color: transparent;
      }

      & .Mui-focused {
        background-color: transparent;
      }
    }
  }
}

.block-photographs {
  position: absolute;
  top: 657px;
  left: 40px;
  width: 740px;
  height: 670px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  .block-photographs-title {
    position: absolute;
    top: 24px;
    left: 24px;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
  }

  .block-photographs-description {
    position: absolute;
    top: 58px;
    left: 24px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .block-photographs-container-inputs {
    position: absolute;
    top: 116px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 24px 0 24px;

    .label-photographs-button {

      .block-photographs-button-title {
        font-family: "Red Hat Display", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        margin-bottom: 12px;
      }

      .block-photographs-button {
        width: 220px;
        height: 133px;
        border-radius: 4px;
        border: 1px solid #d9d9d9 !important;
        text-transform: none !important;
        background-color: #ffffff;
        margin-bottom: 16px;
        transition: 300ms;

        .block-photographs-button-icon {
          margin-bottom: 11px;
        }

        &:hover {
          box-shadow: 0 0 12px rgba(#000000, 0.2);
        }

        &:active {
          transform: scale(0.95);
        }
      }

      & .MuiButton-label {
        display: flex;
        flex-direction: column;
        color: #b4b4b4;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 400;
      }

      & .MuiButton-contained {
        box-shadow: none;
      }
    }
  }
}

.block-buttons {
  position: absolute;
  top: 1375px;
  left: 509px;

  .block-buttons-return {
    width: 124px;
    height: 48px;
    text-transform: none;
    font-family: Lato, sans-serif;
    font-weight: 800;
    font-size: 14px;
    background-color: #ffffff;
    color: #666666;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    margin-bottom: 48px;

    &:hover {
      background-color: #ffffff;
    }
  }

  .block-buttons-save {
    width: 124px;
    height: 48px;
    text-transform: none;
    font-family: Lato, sans-serif;
    font-weight: 800;
    font-size: 14px;
    background-color: #0032a0;
    color: #ffffff;
    margin-left: 24px;
    margin-bottom: 48px;

    &:hover {
      background-color: #0032a0;
    }

    &:disabled {
      background-color: #d9d9d9;
      color: #ffffff;
    }
  }
}
