.user-input-field-container {
    display: flex;
    margin-top: 20px !important;
    margin-left: 40px !important;
}

.user-input-field {
    position: relative;
    width: 338px;
    height: 48px;
    border-radius: 4px;
    background-color: white !important;
    margin-right: 25px !important;
}

.password-eye-icon {
    color: #0032a0;
}

.image-card-div {
    border-radius: 50%;
    margin-left: 40px;
    width: 120px;
    height: 120px;
}

.image-card-button-container {
    display: flex;
    margin-top: 50px;
    padding-bottom: 16px;
}

.user-parameters-modify-button {
    height: 48px;
    width: 153px;
    border-radius: 4px;
    border: 1px solid #d9d9d9 !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #666666 !important;
    text-transform: none !important;
    margin-left: 30px !important;
}

.user-change-password-button {
    height: 48px;
    width: 212px;
    background: #b9cfff !important;
    border-radius: 4px;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #001135 !important;
    text-transform: none !important;
    margin-right: 20px !important;
}
