.title {
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 31px;
    line-height: 130%;
    font-family: 'Red Hat Display', sans-serif;
    color: #292724;
    text-align: start;
    margin-top: 80px;
    margin-left: 40px;
}

.cards-container {
    margin: 40px 64px 40px 24px;
    padding: 0px 24px 30px 24px;
}

.cards-container-title {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.client-table-title {
    position: relative;
    font-style: normal;
    font-size: 31px;
    font-weight: 900;
    line-height: 130%;
    font-family: 'Red Hat Display', sans-serif;
    text-align: start;
    margin-top: 40px;
}

.hotel-configuration-card-container {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    gap: 40px;
    margin-top: 50px;
    margin-right: 20px;
}

.hotel-config-card {
    position: relative;
    background: white;
    width: 290px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px !important;
    margin-right: 20px !important;
    cursor: pointer;
}

.hotel-config-card-icon {
    width: 24px;
    height: 24px;
    margin-top: 10px;
}

.hotel-config-card-title {
    position: relative;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #001135;
    text-align: left;
    margin-top: 20px;
}

.hotel-config-card-subtitle {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    margin-top: 0px !important;
}

.hotel-config-card-pincode-city {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
}

.loading-label {
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    font-weight: 800 !important;
    color: #666666 !important;
}
