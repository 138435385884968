.charterGraphicalNoConfig-info {
  position: absolute;
  top: 248px;
  left: 150px;
  width: 730px;
  height: 102px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 900;
  font-size: 39px;
  line-height: 50.7px;
  text-align: center;
  letter-spacing: 2px;
  color: #d9d9d9;
}

.charterGraphicalNoConfig-info-user {
  position: absolute;
  top: 374px;
  left: 150px;
  width: 730px;
  height: 48px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #666666;
  padding: 0 11rem 0 11rem;
}

.charterGraphicalNoConfig-button {
  position: absolute !important;
  top: 470px;
  left: 454px;
  width: 122px;
  height: 48px;
  background-color: #0032a0 !important;
  color: #ffffff !important;
  box-shadow: none !important;
  text-transform: none !important;

  &:hover {
    background-color: #0032a0;
  }
}