.clients-files-modify-button {
    width: 109px;
    height: 48px;
    margin-right: 20px !important;
    margin-top: 40px !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    background: #b9cfff !important;
    color: #001135 !important;
}
.clients-files-delete-button {
    width: 109px;
    height: 48px;
    margin-right: 80px !important;
    margin-top: 40px !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    background: #fae8e8 !important;
    color: #620525 !important;
}

.client-files-tab-container {
    position: relative;
    margin-top: 48px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.users-container {
    display: flex;
    margin-left: 45px;
    margin-top: 50px;
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 130%;
}

.user-type-tag {
    display: inline-block;
    background: #b9cfff;
    border-radius: 4px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    color: #001135;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
}

.user-rights-tag {
    display: inline-block;
    background: #e1f6fd;
    border-radius: 4px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    color: #001135;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
}
