$top: 50%;
$left: 50%;
.beverages-modal-container {
    position: relative;
    outline: none !important;
    background-color: #ffffff;
    width: 440px;
    top: $top;
    left: $left;
    transform: translate(-$top, -$left);
    text-align: center;
    padding: 40px;
    max-height: 90vh;
    overflow-y: auto;
}

.beverages-modal-icon {
    position: relative;
    width: 50px;
    height: 50px;
}

.beverages-modal-title {
    position: relative;
    font-weight: 900;
    font-size: 25px;
    line-height: 130%;
    color: #292724;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0.02em;
    margin-top: 30px;
}

.beverages-modal-subtitle {
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Red Hat Display', sans-serif;
    color: #666666;
    margin-top: 24px;
    letter-spacing: 0.02em;
}

.success-modal-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    background: #b9cfff;
    color: #001135;
}

.beverages-modal-return-button {
    margin-top: 20px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.beverages-modal-close-icon {
    color: #0032a0;
    width: 26px;
    height: 26px;
    margin-top: -15px;
    margin-right: -15px;
    float: right;
    cursor: pointer;
}

.save-modal-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #ffffff;
}

.beverage-type {
    margin-top: 32px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 20px;
}

.beverage-type-heading {
    color: #43433d;
    display: flex;
    font-weight: bold;
    word-wrap: break-word;
}

.beverage-type-description-container {
    margin-top: 5px;
    color: #666666;
    font-weight: lighter;
    display: flex;
    justify-content: space-between;
}

.beverage-type-description {
    text-align: left;
}

.beverage-quantity-add-delete-container {
    position: relative;
    float: right;
    color: #43433d;
}

.beverage-quantity-cocktail {
    color: #43433d;
    margin-top: 10px;
}

.beverage-type {
    margin-top: 32px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    border-bottom: 1px solid #d9d9d9;
}
.beverage-type:last-child {
    border-bottom: none;
    margin-bottom: 20px;
}

.empty-price {
    display: flex;
    align-items: center;
    padding: 8px 4px;
    height: 24px;
    border-radius: 4px;
    margin-top: 16px;
}

.price-container {
    display: flex;
    align-items: center;
    border-color: #eec6f6;
    padding: 8px 4px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    border: solid 0px;
    width: max-content;
    height: 24px;
    background: #eec6f6;
    border-radius: 4px;
    color: #2d0834;
    margin-top: 16px;
}
