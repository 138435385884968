.restaurant-accordian-container {
    background-color: white !important;
    width: 1000px;
    border-radius: 8px;
    margin-top: 54px;
    margin-left: 240px;
}

.select-meal-text {
    margin-top: 10px;
    font-family: Red Hat Display !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
}

.meal-type {
    margin-top: 32px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    border-bottom: 1px solid #d9d9d9;
}
.meal-type:last-child {
    border-bottom: none;
    margin-bottom: 20px;
}

.meal-type-heading {
    color: #43433d;
    display: flex;
    font-weight: bold;
    word-wrap: break-word;
}

.meal-type-description-container {
    margin-top: 5px;
    color: #666666;
    font-weight: lighter;
    display: flex;
}

.meal-type-description {
    width: 524px;
    word-wrap: break-word;
}

.meal-quantity-add-delete-container {
    position: relative;
    float: right;
    color: #43433d;
    margin-top: 10px;
    margin-left: 80px;
}

.price-container {
    display: flex;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    align-items: center;
    border-color: #eec6f6;
    padding: 8px 4px;
    border: solid 0px;
    height: 24px;
    background: #eec6f6;
    border-radius: 4px;
    color: #2d0834;
    margin-top: 16px;
    margin-bottom: 30px;
}

.quantity-count {
    margin-left: 12px;
    margin-right: 12px;
}

.add-drinks-button {
    display: flex !important;
    height: 48px;
    width: 195px;
    color: #666666 !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
}

.add-drinks-button-container {
    margin-bottom: 20px;
}

.foodlist {
    margin-top: 30px;
    border-bottom: 1px solid #d9d9d9;
}

.restaurantScreen-button-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    
}

.restaurant-buttons-group {
    display: flex;
    margin-left: -20%;

    margin-bottom: 200px;
}

.restaurant-precedent-button {
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.restaurant-precedent-button.MuiButton-text {
    padding: 13.5px 27px;
    text-transform: none !important;
}

.restaurant-save-button.MuiButton-containedPrimary {
    background: #0032a0 !important;
    color: #ffffff !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    line-height: 150% !important;
    font-size: 14px !important;
    margin-left: 10px;
    padding: 13.5px 27px;
    text-transform: none !important;
    border-radius: 25px !important;
}
