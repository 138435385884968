.restaurant-screen-container {
    position: relative !important;
    background: white;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding-left: 24px !important;
    margin: 32px 0px 32px 40px !important;
    width: 80%;
    text-align: left !important;
    box-shadow: none !important;
}

.restaurant-screen-container-title {
    font-family: Red Hat Display !important;
    font-weight: 800 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    text-align: left;
    margin-bottom: 16px;
    padding-top: 24px;
}

.hotel-restaurant-card {
    box-shadow: none !important;
    margin-bottom: 24px;
    margin-right: 30px;
}

.hotel-restaurant-card-title {
    position: relative;
    font-family: 'Red Hat Display' !important;
    font-weight: bold !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
}

.hotel-restaurant-card-description {
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
    overflow-wrap: break-word;
    line-height: 150%;
    height: auto;
    min-height: 45px;
    margin-top: 5px;
}

.hotel-restaurant-card-quantity-div {
    position: relative;
    display: inline-block;
    height: 26px;
    width: max-content;
    font-family: Lato !important;
    font-size: 12px !important;
    font-weight: 800 !important;
   // color: #2d0834 !important;
    padding: 4px 8px !important;
    //background: #eec6f6 !important;
    border-radius: 4px !important;
    margin-top: 7px;
}

.hotel-seminar-study-screen-container {
    position: relative !important;
    background: white;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding-left: 24px !important;
    margin-left: 40px !important;
    width: 80%;
    text-align: left !important;
    box-shadow: none !important;
}

.hotel-seminar-card-title {
    position: relative;
    font-family: 'Red Hat Display' !important;
    font-weight: bold !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #001135 !important;
    margin-top: 20px;
    margin-left: 20px;
}

.hotel-seminar-card-icon-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.hotel-seminar-options-icon {
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: 20px;
    margin-right: 10px;
}

.hotel-seminar-icon-title {
    font-family: Lato !important;
    font-size: 12px !important;
    text-align: left !important;
    color: #666666 !important;
    line-height: 150%;
    margin-top: 3px;
}

.hotel-seminar-icon-value {
    font-family: Lato !important;
    font-size: 12px !important;
    text-align: left !important;
    font-weight: bold !important;
    color: #666666 !important;
    line-height: 150%;
    margin-right: 20px !important;
    margin-top: 3px;
}

.hotel-seminar-card-equip-container {
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.hotel-seminar-card-quantity-div {
    position: relative;
    width: max-content;
    height: fit-content;
    font-family: Lato !important;
    font-size: 12px !important;
    font-weight: 800 !important;
   // color: #2d0834 !important;
    padding: 4px 8px !important;
   // background: #eec6f6 !important;
    border-radius: 4px !important;
    margin-top: 10px;
    margin-right: 8px;
}
