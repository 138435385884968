.skip-step-button {
    width: 168px;
    height: 48px;
    border-radius: 4px;
    font-weight: 800 !important;
    font-family: Lato !important;
    font-size: 14px !important;
    text-transform: none !important;
    margin-right: 20% !important;
    margin-top: 40px !important;
    background: transparent !important;
    color: #0032a0 !important;
}

.tab-container {
    position: relative;
    margin-top: 48px;
    margin-left: 40px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.seminar-tab-title {
    font-family: Lato !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 150%;
    text-transform: none !important;
}

.MuiTab-root {
    padding: 6px 20px !important;
}

.MuiTab-textColorPrimary {
    color: #d9d9d9 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #0032a0 !important;
}

.MuiOutlinedInput-root {
    color: #666666 !important;
    font-family: Lato !important;
    font-size: 14px !important;
}

.description-field {
    position: relative;
    width: 338px;
    height: 128px;
    border-radius: 4px;
    margin-bottom: 40px !important;
}

.seminar-config-add-room-type-button {
    display: flex !important;
    height: 48px;
    width: 195px;
    color: #666666 !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    margin: 32px 0px 48px 40px !important;
}

.seminar-config-add-room-type-button > .MuiButton-label > .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 5px !important;
    height: 24px !important;
    width: 24px !important;
}

.data-input-field {
    position: relative;
    width: 238px;
    height: 48px;
    border-radius: 4px;
}

.price-input-field {
    position: relative;
    width: 338px;
    height: 48px;
    border-radius: 4px;
    margin-right: 16px !important;
}

.config-input-field {
    position: relative;
    width: 220px;
    height: 48px;
    border-radius: 4px;
    margin: 12px 16px 8px 0px !important;
}

.information-icon {
    margin-top: 24px;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color: #d9d9d9;
    cursor: pointer;
}

.seminar-room-config-subtitle {
    position: relative;
    font-family: Lato !important;
    font-size: 12px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: left !important;
    margin-top: 8px !important;
}

.hotel-config-modal-add-photo {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    color: #43433d;
}

$top: 50%;
$left: 50%;
.config-modal-container {
    position: relative;
    background-color: #ffffff;
    width: 550px;
    top: $top;
    left: $left;
    transform: translate(-$top, -$left);
    text-align: center;
    padding: 26px 26px 40px 40px;
    outline: none !important;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 25px !important;
}

.config-modal-close-icon {
    color: #0032a0;
    width: 26px;
    height: 26px;
    float: right;
    cursor: pointer;
}

.config-modal-subtitle {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    color: #43433d;
    font-family: 'Red Hat Display', sans-serif;
    margin-top: 32px;
    margin-bottom: 10px;
    text-align: left;
}

.config-modal-details-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.config-modal-details-icon-label {
    margin-top: 6px;
    position: relative;
    font-family: Lato !important;
    font-size: 14px !important;
    color: #666666;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #8bafff !important;
}

.seminar-checkbox-title {
    position: relative;
    margin-top: 12px !important;
    white-space: nowrap;
    font-family: Lato !important;
    font-size: 16px !important;
    color: #666666;
}

.seminar-checkbox-container {
    display: flex;
    width: 690px;
    justify-content: space-between;
}

.seminar-study-screen-container {
    position: relative !important;
    background: white;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 24px !important;
    margin-left: 40px !important;
    width: 80%;
    text-align: left !important;
    box-shadow: none !important;
}
