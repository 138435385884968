.input-field-number-of-participants {
    width: 65px;
    height: 48px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}
.client-demand-screen-button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}