.forgot-password-subtitle-text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #666666;
    margin-top: 20px;
    width: 360px;
    height: 48px;
    text-align: left;
    float: left;
}

.forgot-field {
    margin-top: 110px;
}

.user-email-field {
    position: relative;
    width: 360px;
    height: 48px;
    border-radius: 4px;
    background-color: white !important;
    margin-right: 25px !important;
}

.forgot-password-button {
    width: 360px;
    height: 48px;
    margin-top: 40px !important;
    text-transform: none !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    font-family: 'Lato', sans-serif !important;
}

.success-modal-forgot-password-icon {
    position: relative;
    width: 50px;
    height: 50px;
    margin-top: 50px;
}

.success-modal-forgot-password-title {
    position: relative;
    font-weight: 900;
    font-size: 25px;
    line-height: 130%;
    color: #292724;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0.02em;
    margin-top: 10px;
}

.success-modal-forgot-password-subtitle {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #666666;
    position: absolute;
    width: 360px;
    height: 24px;
    margin-top: 24px;
    margin-bottom: 62px;
}

.success-modal-forgot-password-button {
    margin-top: 20px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
    margin-bottom: 20px;
}

.banner-forgot-password {
    position: absolute;
    left: 40%;
    top: 0px;
    width: 60%;
    height: 100%;
    object-fit: cover;
    z-index: 100;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    .container {
        margin-left: 45px !important;
    }
    .banner-forgot-password {
        left: 50%;
    }
}
