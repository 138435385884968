.summary-screen-desc {
    display: flex;
   
    margin-top: 16px;
    width: 550px;
    text-align: start;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #666666;
    //margin-left: 33%;
    margin: auto;
    align-content: center;
}

.summary-items-container {
    width: 780px;
    border: 1px solid #d9d9d9;
    padding: 24px;
    //margin-left: 280px;
    margin: auto;
    margin-top: 48px;
    border-radius: 8px;
}
.summary-item-heading {
    text-align: start;
    display: flex;
    justify-content: space-between;
}
.item-heading {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 130%;
    display: flex;
}

.modifier-text {
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    color: #0032a0;
    margin-left: 500px;
    cursor: pointer;
}

.summary-item {
    text-align: start;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 720px;
}

.demand-title {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #43433d;
}

.column {
    float: left;
    width: 45%;
    text-align: start;
    margin-top: 24px;
}
.row:after {
    content: '';
    display: table;
    clear: both;
}

.resataurant-column {
    float: left;
    width: 33%;
    text-align: start;
    margin-top: 24px;
}

.demand-content {
    display: table;
    margin-top: 6px;
    background: #b9cfff;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    //color: #001135;
}

.commission-content {
    display: table;
    margin-top: 6px;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: Lato;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    color: #001135;
}

.number-of-participants {
    text-align: start;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #666666;
    margin-top: inherit;
    margin-left: 10px;
}

.accomodation-content {
    font-family: Red Hat Display;
    font-style: normal;
    font-size: 14px;
    color: #666666;
}

.event-content {
    font-family: Red Hat Display;
    font-style: normal;
    font-size: 14px;
    color: #666666;
}

.summaryScreen-buttons-group {
   display: flex;
   margin-left: -16%;
    margin-bottom: 48px;
}

.summaryScreen-button-container {
    display: flex;
    justify-content: center;
    margin-top: 48px;
   
}
