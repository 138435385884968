.admin-addclient-form-fields-container {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
}

.admin-add-client-form-quotelo-access-subtitle {
    color: #666666;
    margin-bottom: 24px;
}

.admin-client-add-form-button-container {
    height: 80px;
    width: 780px;
}

.client-save-button {
    text-transform: none !important;
}

.cancel-button {
    text-transform: none !important;
}
