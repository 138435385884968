.client-screen-quote-subtitle {
    width: 510px;
    font-family: Lato !important;
    font-size: 16px !important;
    color: #666666 !important;
    text-align: left;
    margin-top: 24px;
    margin-left: 40px;
    line-height: 150%;
}

.client-screen-button-container {
    margin-right: 80px;
    margin-top: 40px;
}

.client-screen-subtitle-button-container {
    display: flex;
    justify-content: space-between;
}

.client-screen-quote-url-link {
    margin-right: 100px;
    margin-top: 20px;
}

.client-screen-quote-url-button {
    height: 48px;
    width: 203px;
    background: #fdc2ff !important;
    border-radius: 4px;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #001135 !important;
    text-transform: none !important;
}

.client-screen-send-email-button {
    height: 48px;
    width: 203px;
    background: #0032a0 !important;
    border-radius: 4px;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    text-transform: none !important;
    margin-right: 20px !important;
}

.client-screen-access-booking-button {
    height: 48px;
    width: 267px;
    background: #b9cfff !important;
    border-radius: 4px;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #001135 !important;
    text-transform: none !important;
    margin-right: 20px !important;
}

.recent-client-access-title {
    position: relative;
    font-family: 'Red Hat Display' !important;
    font-weight: bold !important;
    font-size: 20px !important;
    text-align: left !important;
    color: #43433d !important;
    padding-top: 15px;
    margin-top: 40px;
    margin-left: 40px;
}

.client-screen-export-quote-button {
    height: 48px;
    width: 153px;
    border-radius: 4px;
    border: 1px solid #d9d9d9 !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #666666 !important;
    text-transform: none !important;
    margin-right: 80px !important;
    margin-top: 40px !important;
}
.client-screen-export-quote-button a {
    color: #666666 !important;
}

.client-screen-table-container {
    box-shadow: none !important;
    margin: 20px 80px 40px 40px !important;
    border: 1px solid #d9d9d9;
    background-color: transparent !important;
    border-radius: 4px;
    padding: 10px 24px;
}

.no-client-details-title {
    font-weight: 900;
    font-size: 39px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #d9d9d9;
    margin-top: 160px;
}

.name-input-field {
    position: relative;
    width: 172px;
    height: 48px;
    border-radius: 4px;
    margin-top: 12px !important;
}

.email-input-field {
    position: relative;
    width: 360px;
    height: 48px;
    border-radius: 4px;
    margin-top: 12px !important;
}

.modal-required-title {
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #87189d;
    text-align: left;
    margin-top: 32px;
}

.client-details-modal-close-icon {
    color: #0032a0;
    width: 26px;
    height: 26px;
    float: right;
    cursor: pointer;
    margin-top: -25px;
    margin-right: -20px;
}

.client-onboarding-logo {
    position: relative;
    width: 166px;
    height: 55px;
    object-fit: contain;
    float: left;
    margin-top: 38px;
    margin-left: 38px;
}

.client-onboarding-title {
    font-family: Red Hat Display !important;
    font-weight: 900 !important;
    font-size: 31px !important;
    line-height: 150% !important;
    color: #292724;
    padding-top: 48px;
    padding-right: 204px;
}

.client-onboarding-address-container {
    display: flex;
    margin-top: 26px;
    justify-content: center;
}

.client-onboarding-address-label {
    margin-left: 11px;
    padding-top: 3px;
    font-family: Lato !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666;
}

.client-onboarding-image-container {
    position: relative;
    width: 549px;
    height: 322px;
    margin-top: 33px;
    object-fit: contain;
}

.client-onboarding-image-subtitle {
    margin-top: 15px;
    font-family: Lato !important;
    font-size: 16px !important;
    line-height: 100% !important;
    color: #666666;
}

.client-onboarding-qoute-button {
    margin: 40px 0px !important;
    width: 150px;
    height: 48px;
    border-radius: 4px;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    text-transform: none !important;
    box-shadow: none;
    border-radius: 25px !important;
}
