.setting-card-container {
  margin-top: 5rem;
  margin-left: 40px;
  display: flex;

  .setting-card {
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-sizing: border-box;
    width: 290px;
    height: 164px;
    cursor: pointer;
    background-color: #ffffff;
    transition: 300ms;

    &.disabled-card {
      transform: none;
      cursor: default;

      &:hover {
        box-shadow: none;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(#ffffff, 0.5);
        backdrop-filter: blur(2px);
        left: 0;
        top: 0;
        border-radius: 4px;
        z-index: 1;
      }

      &:after {
        position: relative;
        content: 'Prochainement';
        font-weight: bold;
        color: #000000;
        z-index: 10;
      }
    }

    .setting-card-icon {
      position: absolute;
      left: 6.9%;
      top: 12.2%;
      width: 48px;
      height: 48px;
      background-color: #b9cfff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .setting-card-icon-icon {
        width: 24px;
        height: auto;
      }
    }

    .setting-card-content {
      position: absolute;
      top: 88px;
      left: 20px;

      .setting-card-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        font-family: 'Red Hat Display', sans-serif;
        color: #001135;
        text-align: left;
      }

      .setting-card-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        font-family: Lato, sans-serif;
        margin-top: 8px;
      }
    }

    &:nth-child(1n+2) {
      margin-left: 2rem;
    }

    &:hover {
      box-shadow: 0 0 12px rgba(black, 0.2);
    }

    &:active {
      transform: scale(0.95);
    }
  }
}