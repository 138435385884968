.client-Seminar-note-container {
    background: #e1f6fd;
    height: 74px;
    width: 692px;
    margin-top: 45px;
}

.client-Seminar-text {
    font-size: 14px;
    font-family: Lato;
    padding: 16px 16px;
    text-align: left;
    color: #01465e;
}

.client-Seminar-offer-container {
    width: 526px;
    height: auto;
    margin-top: 32px;
    margin-bottom: 24px;
}

.client-Seminar-offer-title {
    font-size: 18px;
    font-family: Red Hat Display;
    text-align: left;
    color: #292724;
    font-weight: bold;
    margin-bottom: 5px;
}

.client-Seminar-offer-subtitle {
    margin-top: 2px;
    font-family: Lato;
    font-size: 16px !important;
    display: block;
    width: 650px;
    height: auto;
    word-wrap: break-word;
    color: #666666;
    text-align: left;
}

.client-Seminar-grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 24px;
    width: 706px;
    column-gap: 16px;
    height: 63px;
}

.client-Seminar-grid-tile {
    font-size: 14px;
    font-family: Red Hat Display;
    color: #43433d;
    text-align: start;
    font-weight: bold;
    width: 338px;
    height: 63px;
}

.client-Seminar-grid-from {
    font-size: 14px;
    font-family: Lato;
    font-weight: normal;
    color: #666666;
    text-align: left;
}

.client-Seminar-grid-subtitle {
    margin-top: 2px;
    font-size: 14px;
    font-family: Lato;
    font-weight: normal;
    color: #666666;
    text-align: left;
}

.client-Seminar-rate-tag {
    width: 54px;
    height: 26px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: #eec6f6;
    border-radius: 4px;
    margin-top: 4px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
}

.client-Seminar-choose-Option {
    font-weight: bold;
    font-family: Red Hat Display;
    text-align: left;
    font-size: 18px;
    width: 333px;
    height: 27px;
    margin-top: 26px;
}

.client-Seminar-radio-button {
    width: 338px;
    height: 24px;
}

.client-Seminar-radio-container {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 706px;
    column-gap: 16px;
    font-family: Lato;
    height: 33px;
}
