.component-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.title {
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 31px;
    line-height: 130%;
    font-family: 'Red Hat Display', sans-serif;
    color: #292724;
    text-align: start;
    margin-top: 40px;
    margin-left: 40px;
}

.data-container {
    border: 1px solid #d9d9d9;
    margin: 40px 64px 40px 24px;
    padding: 0px 24px 30px 24px;
    background-color: #ffffff;
    filter: blur(8px);
}

.table-title-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.table-title {
    position: relative;
    font-style: normal;
    font-weight: 900;
    line-height: 130%;
    font-family: 'Red Hat Display', sans-serif;
    text-align: start;
}

.client-button {
    font-weight: bold !important;
    background-color: #c5d3f0;
    color: #2c509e;
    height: 30px;
    width: max-content;
    padding: 4px 10px 0px 10px;
    border-radius: 5px;
    text-align: center;
}

.view-details-button {
    font-weight: bold !important;
    background-color: #ffffff;
    color: #09090a;
    height: 30px;
    width: max-content;
    padding: 4px 10px 0px 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
}

.construction {
    position: absolute;
    top: 50%;
    left: 25%;
}

.data-title {
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 130%;
    font-family: 'Red Hat Display', sans-serif;
    color: #292724;
    text-align: center;
}

.data-details {
    position: relative;
    width: 550px;
    height: 96px;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Lato', sans-serif;
    color: #666666;
    text-align: center;
    margin-top: 24px;
}
