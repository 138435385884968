.client-Study-Day-title {
    font-family: Red Hat Display !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    color: #292724;
    display: table-row;
    height: 52px;
    text-align: left;
    float: left;
}

.client-Study-Day-sub-title {
    font-family: Lato !important;
    width: 600px;
    font-size: 16px !important;
    display: table-row;
    color: #666666;
    float: left;
    text-align: justify;
}

.seminar-checkbox-title {
    position: relative;
    margin-top: 9px;
    font-family: Lato !important;
    font-size: 16px !important;
    color: #666666;
}

.study-day-checkbox-container {
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    margin: auto;
    width: 785px;
    padding: 24px;
}

.Study-day-checkbox-grid-container {
    display: flex;
    //width: 780px;
    flex-wrap: wrap;
    grid-gap: 55px;
}

.client-study-day-dropdown {
    float: left;
    margin-left: -7px;
}

.client-study-day-NumberOfParticipants-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.client-study-day-NumberOfParticipants-container {
    width: 167px;
    height: 71px;
    margin-bottom: 12px;
}

.quantity-container {
    position: relative;
    float: left;
    color: #43433d;
    margin-top: 20px;
}

.quantity-count {
    margin-left: 12px;
    margin-right: 12px;
}

.client-Seminar-Study-Day {
    position: relative !important;
    background: white;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 0px 24px 0px 24px !important;
    margin: auto;
    width: 740px;
    text-align: left !important;
    box-shadow: none !important;
}

.Client-Study-day-precedent-button {
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 180% !important;
    width: 119px;
    height: 48px;
    padding: 27px 13.5px;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.Client-Seminar-Study-Day-button-container {
    height: 88px;
    display: flex;
    justify-content: center;
}

.alacarte-icon-container {
    margin-top: 6px;
    font-size: 14px !important;
    text-align: left !important;
    color: #43433d !important;
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    margin-top: 8px;
}

.alacarte-container {
    display: flex;
    width: 330px;
    height: 24px;
    margin-top: 1px;
}

.alacarte-icon-subtitle {
    font-family: Lato !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #666666 !important;
}

.alacarte-accommodation-card-quantity-div {
    position: relative;
    font-family: Lato !important;
    font-size: 12px !important;
    font-weight: 800 !important;
    width: 60px;
    height: 26px;
    color: #2d0834 !important;
    padding: 4px 8px !important;
 
    border-radius: 4px !important;
    margin-right: 8px !important;
    margin-top: 4px;
    margin-bottom: 8px;
}

.alacarte-breaks-subtitle {
    color: #666666;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
}

.alacarte-accommodation-card-description {
    position: relative;
    font-family: Lato !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #666666;
    text-align: center;
    margin: 24px auto 8px;
    width: 335px;
    height: 48px;
}

$top: 50%;
$left: 50%;
.alacarte-modal-container {
    position: relative;
    background-color: #ffffff;
    width: 640px;
    top: $top;
    left: $left;
    transform: translate(-$top, -$left);
    text-align: center;
    padding: 26px 26px 40px 40px;
    outline: none !important;
    max-height: 90vh;
    border-radius: 25px!important;
}
