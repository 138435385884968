.contact-screen-main-title {
    font-family: Red Hat Display !important;
    font-weight: 900 !important;
    font-size: 31px !important;
    line-height: 150% !important;
    color: #292724;
}

.contact-screen-require-field-title {
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #87189d;
    text-align: left;
    margin-top: 16px;
}

.contact-screen-container {
    border: 1px solid #d9d9d9;
    background-color: white !important;
    border-radius: 8px;
    margin-top: 20px;
    margin: auto;
    width: 865px;
    height: 318px;
    padding: 20px;
}

.contact-screen-sub-title {
    display: flex;
    font-family: Red Hat Display !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    color: #292724;
    margin-left: 10px;
}

.user-input-field-container {
    display: flex;
    margin-top: 28px !important;
    margin-left: 10px !important;
}

.user-input-field {
    position: relative;
    width: 390px;
    height: 48px;
    border-radius: 4px;
    background-color: white !important;
    margin-right: 25px !important;
}

.contact-checkbox-container {
    display: flex;
    margin-top: 20px !important;
    margin-left: 279px !important;
}

.contact-checkbox-title {
    position: relative;
    margin-top: 9px;
    font-family: Lato !important;
    font-size: 16px !important;
    color: #666666;
}

.contact-button-container {
    width: 1145px;
    margin-top: 50px;
}

.contact-buttons-group {
    float: right;
}

.contact-precedent-button {
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.contact-precedent-button.MuiButton-text {
    padding: 13.5px 27px;
    text-transform: none !important;
}

.contact-send-request-button.MuiButton-contained.Mui-disabled {
    background: #d9d9d9 !important;
    color: #fafafa !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    line-height: 150% !important;
    font-size: 14px !important;
    padding: 13.5px 27px;
    text-transform: none !important;
}

.contact-send-request-button.MuiButton-containedPrimary {
    background: #0032a0 !important;
    color: #ffffff !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    line-height: 150% !important;
    font-size: 14px !important;
    margin-left: 10px;
    padding: 13.5px 27px;
    text-transform: none !important;
}
