.required-field-title {
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #87189d;
    text-align: left;
    margin-top: 50px;
    margin-left: 40px;
}

.restoration-screen-container {
    position: relative;
    background: white;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 24px 0px 24px !important;
    margin-left: 40px;
    margin-right: 250px;
}

.restoration-screen-container-title {
    font-family: Red Hat Display !important;
    font-weight: 800 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    text-align: left;
    margin-bottom: 24px;
}

.restoration-screen-container-subtitle {
    font-family: Red Hat Display !important;
    font-weight: normal !important;
    font-size: 14px !important;
    font-style: normal;
    line-height: 150% !important;
    text-align: left;
    color: #666666;
}

.restoration-screen-option-title {
    font-family: Red Hat Display !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    text-align: left;
    color: #43433d;
}

.radio-button {
    color: #666666;
    font-family: Lato !important;
    font-size: 16px !important;
}

.input-field {
    position: relative;
    width: 338px;
    height: 48px;
    border-radius: 4px;
}

.text-field {
    position: relative;
    width: 338px;
    height: 128px;
    border-radius: 4px;
}

.MuiOutlinedInput-root {
    width: inherit;
    height: inherit;
}

.button-container {
    float: right;
    margin: 40px 250px 40px 0px;
}

.save-data-button {
    width: 124px;
    height: 48px;
    border-radius: 4px;
    font-weight: 800 !important;
    font-family: Lato !important;
    font-size: 14px !important;
    text-transform: none !important;
    margin-left: 16px !important;
}

.save-data-button.MuiButton-contained.Mui-disabled {
    background: #d9d9d9 !important;
    color: #ffffff !important;
}

.save-data-button.MuiButton-containedPrimary {
    background: #0032a0 !important;
    color: #ffffff !important;
}

.retour-button {
    width: 97px;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #666666 !important;
    border: 1px solid #d9d9d9 !important;
}

.accommodation-screen-container {
    background: white;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 0px 24px 0px 24px !important;
    margin-left: 40px !important;
    text-align: left !important;
    box-shadow: none !important;
}

.input-fields-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 692px;
    margin-bottom: 16px;
}

.MuiAccordion-root:before {
    height: 0px !important;
}

.MuiAccordionDetails-root {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
}

.MuiAccordionSummary-root {
    padding: 0px !important;
}

.MuiAccordionSummary-content {
    margin: 0px !important;
}

.hotel-config-add-photo-button {
    display: flex !important;
    height: 48px;
    width: 204px;
    color: #666666 !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    margin-top: 10px !important;
    margin-bottom: 24px !important;
}

.hotel-config-add-photo-button > .MuiButton-label > .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 5px !important;
    height: 24px !important;
    width: 24px !important;
}

.hotel-config-add-room-type-button {
    display: flex !important;
    height: 48px;
    width: 264px;
    color: #666666 !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    margin: 32px 0px 48px 40px !important;
}

.hotel-config-add-room-type-button > .MuiButton-label > .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 5px !important;
    height: 24px !important;
    width: 24px !important;
}

.accommodation-button-container {
    float: right;
    margin: 40px 16% 40px 0px;
}

.hotel-config-add-photo-button-field {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 48px;
    width: 338px;
    margin-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.accordion-photo-field-value {
    font-family: Lato;
    font-size: 14px;
    width: 285px;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: #666666;
}

.loading-accommodation-icon {
    margin-top: -45px;
    margin-left: 210px;
}

.accordion-photo-field-value {
    font-family: Lato;
    font-size: 14px;
    width: 285px;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: #666666;
}

.loading-accommodation-icon {
    margin-top: -45px;
    margin-left: 210px;
}

.MuiAccordionSummary-content {
    justify-content: space-between;
}

.hotel-accommodation-delete-button {
    display: flex !important;
    height: 32px;
    width: 85px;
    color: #620525 !important;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    line-height: 150% !important;
    margin-top: 24px !important;
    background-color: #fae8e8 !important;
}
