.back-arrow-icon {
    float: left;
    margin-top: 32px;
    margin-left: 80px;
    position: relative;
    width: 24px !important;
    height: 24px !important;
    color: #0032a0;
    cursor: pointer;
}
.client-profile-mainContainer{
    
    flex-direction: row;
    
}
.client-profile-mainContainer > .flex-item {
    
    //flex:0.3 0 auto !important ;
}
.client-profile-mainContainer > .flex-item2 {
    
//    flex:0.7 0 auto !important ;
    flex: 1 0 auto !important ;
}

.profile-flex-item{
    height: 100vh !important;
    width: 200px !important;
    
}
.flex-item{
    
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 220px !important;
    height: 100vh !important;
}
.flex-item-image{
   position: fixed !important;
   object-fit: cover;
   width: 220px !important;
   height: 100vh !important;
    
}
.study-flex-item{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 100vh !important;
    margin-right: 15%!important;
}


.back-arrow-active-content {
    position: relative;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #0032a0;
    margin-top: 32px;
    margin-left: 15px;
    line-height: 150%;
    cursor: pointer;
}

.client-profile-screen-container {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile-screen-content {
        display: flex;
        justify-content: center;
    }
}

.profile-screen-main-title {
    font-family: Red Hat Display !important;
    font-weight: 900 !important;
    font-size: 31px !important;
    line-height: 150% !important;
    color: #292724;
}
.client-screen-skip-button-container {
    width: 740px;
    display: flex;
    justify-content: flex-end;
}

.profile-screen-require-field-title {
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    
    text-align: center;
    margin-top: 16px;
    margin-bottom: 10px;
}

.profile-screen-sub-title {
    font-family: Red Hat Display !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    color: #292724;
    margin-top: 48px;
    margin-bottom: 24px;
    //margin-left: 33%;
}

.profile-screen-cards {
    width: 233px;
    height: 133px;
    margin-right: 20px;
    margin-bottom: 24px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none !important;
    text-align: center;
    cursor: pointer;
}

.profile-screen-card-icon-container {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 24px auto 16px;
}
.profile-screen-card-icon-invert {
    filter: invert(120%);
}

.profile-screen-card-success-icon {
    width: 48px;
    height: 48px;
    margin: 24px auto 16px;
}

.profile-screen-card-title {
    font-family: Lato !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #001135;
}

.profile-screen-save-data-button {
    width: 102px;
    height: 48px;
    border-radius: 25px !important;
    font-weight: 800 !important;
    font-family: Lato !important;
    font-size: 14px !important;
    text-transform: none !important;
    margin: 18px 0px!important;
    //margin-left: 42% !important;
}

.profile-screen-save-data-button.MuiButton-contained.Mui-disabled {
    background: #d9d9d9 !important;
    color: #ffffff !important;
    border-radius: 25px !important;
}

.profile-screen-save-data-button.MuiButton-containedPrimary {
    background: #0032a0 !important;
    color: #ffffff !important;
    border-radius: 25px !important;
}

.tourism-radio-modal-title {
    position: relative;
    font-weight: 900;
    font-size: 25px;
    line-height: 130%;
    color: #292724;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0.02em;
}

.tourism-radio-modal-subtitle {
    position: relative;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #292724;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0.02em;
    margin-top: 40px;
    text-align: left;
}

.profile-success-modal-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important; 
    color: #ffffff;
}

.profile-success-modal-disabled-button {
    margin-top: 48px !important;
    width: 100%;
    height: 48px;
    text-transform: none !important;
    font-family: Lato !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    background: #fafafa !important;
    color: #d9d9d9 !important;
}

.profile-input-field {
    position: relative;
    width: 360px;
    height: 48px;
    border-radius: 4px;
    margin: 8px 0px 20px 0px !important;
}

.client-screen-title-container {
    display: flex;
    //width: 760px;
    justify-content: center;
}
.client-screen-title-skip-container{
    position: relative;
    
}

.client-screen-title-skip-button {
    position: absolute !important;
    top: -20px !important;
    right: 10% !important;
    width: 168px;
    height: 48px;
    border-radius: 4px;
    font-weight: 800 !important;
    font-family: Lato !important;
    font-size: 14px !important;
    text-transform: none !important;
    margin-right: 20% !important;
    background: transparent !important;
    
}

.client-demand-screen-data-container {
    width: 740px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px;
}

.client-demand-screen-button-container {
    width: 740px;
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;
    padding-bottom: 48px;
}

.client-demand-screen-count-increment-disable {
    min-width: 24px !important;
    height: 24px !important;
    border: 1.5px solid #d9d9d9 !important;
    padding: 0px !important;
}

.client-demand-screen-increment-icon {
    width: 12px !important;
    height: 12px !important;
    color: #666666;
}

.client-demand-screen-count-increment {
    min-width: 24px !important;
    height: 24px !important;
    border: 1.5px solid #666666 !important;
    padding: 0px !important;
}

.client-demand-screen-increment-icon-disable {
    width: 12px !important;
    height: 12px !important;
    color: #d9d9d9;
}

.client-demand-screen-count-value {
    position: relative;
    font-size: 16px;
    font-family: Lato !important;
    color: #666666;
    padding: 0px 12px;
}

.client-demand-screen-date-selector {
    width: 338px;
    height: 48px;
    background: #ffffff;
    font-size: 14px;
}

.client-event-requirement-needs {
    font-family: Red Hat Display !important;
    font-weight: 800 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    text-align: left;
    margin-bottom: 12px;
}

.client-event-requirement-subtitle {
    position: relative;
    font-size: 16px;
    font-family: Lato;
    color: #666666;
    text-align: left;
    margin-bottom: 30px;
}
.Profile-progressBar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    //position: relative;
    //margin: 40px 0 20px 0;
    //left: 30%;
}
.Profile-progressBar-text {
    position: absolute;
    top: 10px;
    left: 0;
}
.Client-screen-hotel-name{
    padding-top: 35px;
    padding-left: 5px;
    font-size: 16px;
}